* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: #161823;
  overflow-y: overlay;
}

html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

img {
  border: none !important;
}

a {
  font-family: 'Arial';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgb(255, 0, 38);
  text-decoration: none;
  cursor: pointer;
}

li {
  text-decoration: none;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 5px 0;
}

p {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 6.361vh;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
  background-color: #ffffff;
}

.inner {
  height: 100%;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-link {
  display: flex;
  cursor: pointer;
}

.logo-brand {
  margin-top: 17px;
  margin-bottom: 17px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #414141;
  border-left: 1px solid #414141;
  padding-left: 15px;
}

.logo {
  height: 50px;
}

.footer {
  height: 3.578vh;
  padding: 0 15px;
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-img-logo {
  height: 80%;
}

.footer-brand {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #414141;
}

.footer-copyright {
  padding-right: 20px;
}

.footer-copyright {
  padding-right: 20px;
}

.footer-info > span {
  padding: 0px 6px;
  color: #414141;
  font-size: 14px;
}

.icon {
  width: 24px;
  height: 24px;
}

.footer-info > span:first-child {
  border-right: 1px solid #414141;
}

.container {
  align-items: stretch;
  background: url('../../assets/images/backgroundEcoSystem.jpg') no-repeat 50% fixed;
  background-size: cover;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.content-left {
  width: 25%;
  background: rgba(255, 255, 255, 0.7);
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 64px;
  padding-right: 32px;
  display: flex;
  align-items: center;
}

.item-project {
  margin-bottom: 20px;
}

.title-project-name {
  font-size: 20px !important;
  font-weight: 600;
}

.item-project-go {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.item-project-more {
  display: flex;
  align-items: center;
  color: rgb(255, 0, 38);
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.content-right {
  margin-top: 6.361vh;
  width: 75%;
  left: 25%;
  right: auto;
}

.title-describe {
  margin-top: 50px;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
}

.title-project {
  font-size: 36px;
  text-align: center;
}

.active {
  color: black;
}

.key-eco-type {
  visibility: hidden;
}

.description {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 1px;
}

.list-module {
  margin-top: 20px;
  padding-left: 20px;
}

.module-eco_build .module-eco_sales .module-eco_pro {
  padding-left: 22px;
}

.icon-tick {
  background-image: url('../../assets/icon/Tick.svg');
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.icon-close {
  background: url('../../assets/icon/Close.svg');
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.footer-license {
  text-decoration: underline rgb(255, 0, 38) solid !important;
  .text-red {
    color: rgb(255, 0, 38);
  }
}

.icon-module {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.icon-more {
  width: 24px;
  height: 24px;
}

.icon-more-is-active {
  transform: rotate(90deg);
}

.avatar {
  position: relative;

  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgb(45, 45, 45);

  span {
    color: rgb(255, 255, 255);
  }
}

.actions {
  display: flex;
  align-items: center;
}

.show {
  &__action {
    z-index: 99;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 6.361vh;
    right: 15px;
    &-icon {
      padding: 4px;
      &:hover {
        background-color: rgba(23, 117, 224, 0.1);
      }
    }
    .navbar,
    .navbar-brand {
      display: flex;
      align-items: center;
    }

    .navbar-brand {
      .logo {
        height: 50px;
      }

      .logo-brand {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #414141;
        border-left: 1px solid #414141;
        padding-left: 15px;
      }
    }
    .blockDrop {
      display: contents;
    }
    &-drop {
      width: 215px;
      background: #fff;
      height: 110px;
      padding: 12px;
      box-sizing: border-box;
      border-left: 1px solid #ccced0;
      border-right: 1px solid #ccced0;
      border-bottom: 1px solid #ccced0;
      border-radius: 0px 0px 6px 6px;
    }

    &-btnLogout {
      align-items: center;
      width: 100%;
      display: flex;
      cursor: pointer !important;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin: 0;
      }

      // &:hover {
      //   background-color: var(--default-layout-background-supporting-gray1);
      // }
    }

    &-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #414141;
      margin: 0;
      &-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin: 4px 0 16px 0;
        text-transform: capitalize;
        white-space: normal;
        text-overflow: ellipsis;
        width: 97%;
      }
    }
  }
}

.title-more {
  color: red;
  font-size: 16px;
}
