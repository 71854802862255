@import 'normalize.css';
@import '../color';
:root {
  --laptop-width: 1200px;
  --pc-width: 1400px;
  --primary: #fe2c55;
  --black: #000;
  --white: #fff;
  --text-color: #161823;
  --color-error: rgb(255, 0, 38);
  --font-family: 'Arial', sans-serif;
  --color-border: #d9d9d9; // Default layout
  --default-layout-header-height: 6.361vh;
  --default-layout-footer-height: 3.578vh;
  --default-layout-aside-height: 6.5vw;
  --default-layout-width: 100%;
  --default-layout-horizontal-spacer: 15px;

  --default-layout-background-aside: rgb(115, 115, 115);
  --default-layout-background-gray: rgb(45, 45, 45);
  --default-layout-background-white: rgb(255, 255, 255);
  --default-layout-background-supporting-gray1: rgb(217, 217, 217);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-family);
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: var(--text-color);
  overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

// Custom tippy tooltip
body {
  .tippy-box {
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: rgba(84, 84, 84, 0.92);
  }

  .tippy-arrow {
    color: rgba(84, 84, 84, 0.92);
  }

  .tippy-content {
    padding: 8px 9px;
  }

  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -8px;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  .ant-form-item-required::before {
    content: none !important;
    visibility: hidden;
  }
  .ant-form-item-required::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: var(--default-layout-background-gray);
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
    visibility: initial !important;
    top: -3px;
  }
  .form-item-information {
    .ant-row {
      .ant-col {
        .ant-form-item-required {
          position: relative;
          img {
            top: 0px;
          }
          .label-required {
            position: relative;
            margin-right: 10px;
            &:after {
              position: absolute;
              display: inline-block;
              margin-inline-end: 4px;
              color: var(--default-layout-background-gray);
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*' !important;
              visibility: initial !important;
              top: -1px;
            }
          }
          &:after {
            content: '' !important;
          }
        }
      }
    }
  }
  .pages__createProject {
    .content__action {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .component__attachment {
    .ant-upload-text {
      margin-bottom: 64px !important;
    }
  }
  .modal__confirm {
    text-align: center;
    .title__container {
      display: flex;
      align-items: center;
      .icon {
        flex: left;
      }
      .title_header {
        flex: start;
        font-weight: bold;
        margin-left: 5px;
        font-size: larger;
      }
      .title {
        flex: auto;
        font-weight: bold;
      }
    }
    .description {
      margin-bottom: 10px;
      margin-top: 0px;
    }
  }
  .ant-table-header {
    border-radius: 0 !important;
  }
}
.suffix-label {
  color: #81817f;
}

.list-item__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.list-item__status,
.list-item__title {
  text-align: center;
}

.text-tooltip {
  color: #000;
}
// Custom CSS Site Usage
.site-usage__form {
  .form-item-custom {
    margin-bottom: 0 !important;
  }
  .site-usage__label--after-none {
    margin-bottom: 0 !important;
    .ant-form-item-label > label::after {
      content: none !important;
    }

    // .component__attachment--listFiles-content .listFiles__item--title {
    //   width: 300px !important;
    // }

    .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .color-required {
    .ant-form-item-label > label {
      color: var(--primary);
    }
  }
}

.tab-action {
  display: flex;
  gap: 8px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
.ant-divider-inner-text {
  span {
    color: var(--text-color) !important;
  }
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: #1677ff !important;
  }
}

.ant-table-thead .ant-table-row-expand-icon-cell {
  background-color: var(--default-layout-background-supporting-gray1) !important;
}

.spinning-center-modal-import {
  position: fixed !important;
  inset: 0;
  z-index: 1010 !important;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.45);
  pointer-events: none;

  .ant-spin-dot .ant-spin-dot-spin {
    margin: 0 auto;
    top: 50% !important;
    bottom: 50% !important;
  }

  :where(.css-dev-only-do-not-override-6139ys).ant-spin .ant-spin-dot {
    top: 50% !important;
    bottom: 50% !important;
  }

  .ant-spin .ant-spin-dot {
    top: 50% !important;
    bottom: 50% !important;
  }
  span {
    top: 50% !important;
    bottom: 50% !important;
  }
}
.app-table-modal-import {
  th,
  td {
    text-align: center !important;
  }
}

.attachment-module-custom-width {
  width: 66.6%;
}

@media only screen and (max-width: 1400px) {
  .bases__padding--form {
    padding: 0 42px;
  }
  .pages__subProjectDetail {
    padding: 20px 60px 10px 140px !important;

    &--content {
      height: auto !important;
      margin-bottom: 32px !important;
    }
  }

  .project-page {
    .table-data {
      .table-group {
        min-height: calc(52vh) !important;
      }
    }
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1920px) {
  .bases__padding--form {
    padding: 0 64px;
  }
}

.icon-custom {
  width: 24px;
  height: 24px;
}

.icon-home {
  width: 80px;
  height: 80px;
}
@media only screen and (max-width: 1700px) and (min-width: 1400px) {
  .tab-action {
    display: flex;
    gap: 8px;

    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }
  }
}
.tooltip-style {
  color: rgba(0, 0, 0, 0.88);
  text-align: center;
}

.ant-tree .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #e60012 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
}

/** Custom CSS for Ant Design Checkbox */
/** Overwrite background color of checkbox */

.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: rgb(255, 0, 38);
  border-color: rgb(255, 0, 38);
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #ff7875 !important;
}

.ant-checkbox-checked:hover .ant-checkbox-inner {
  background-color: #ff7875 !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #ff7875 !important;
}

/** End Custom CSS for Ant Design Checkbox */

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  width: 5.7142857142857135px;
  height: 9.142857142857142px;
  background-color: #e60012 !important;
  color: #fff !important ;
  content: '';
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  inset-inline-start: 21.5%;
}
.color-black {
  color: black !important;
}
.ant-typography-color-red {
  color: #e60012 !important;
}

.project-import-overwrite-color {
  color: rgba(0, 0, 0, 0.88);
  text-align: center;
}
.icon-tooltip {
  position: absolute;
  right: -10px;
}

.mcf-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
  }

  .confirm {
    background: #e60012;
    color: $color-white;
    .zoom-button {
      color: $color-white;
    }
    &:hover {
      background-color: #e60012;
    }
  }

  .danger {
    background: $inspire-red;
    color: $color-white;
    .zoom-button {
      color: $color-white;
    }

    &:hover {
      background-color: #ff7875;
    }
  }

  .cancel {
    border: 1px solid $sp-gray-2;

    &:hover {
      background-color: $sp-gray-1;
    }
  }
}
.modal__deactive {
  text-align: center;
  .title__container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .icon {
      flex: left;
    }
    .title {
      font-weight: bold;
      display: flex;
      padding-top: 2px;
    }
  }
  .description {
    margin-bottom: 10px;
    margin-top: 0;
    display: flex;
    padding-left: 25px;
  }
  .mcf-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      outline: none;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      background-color: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      user-select: none;
      touch-action: manipulation;
      font-size: 14px;
      height: 32px;
      padding: 4px 15px;
      border-radius: 6px;
    }

    .deactive {
      background: $inspire-red;
      color: $color-white;
      .zoom-button {
        color: $color-white;
      }
      &:hover {
        background-color: $inspire-red;
      }
    }

    .danger {
      background: $inspire-red;
      color: $color-white;
      .zoom-button {
        color: $color-white;
      }

      &:hover {
        background-color: #ff7875;
      }
    }

    .cancel {
      border: 1px solid $sp-gray-2;

      &:hover {
        background-color: $sp-gray-1;
      }
    }
  }
}

button[type='button'].ant-btn {
  border-radius: 22px;
}

button[type='submit'].ant-btn {
  border-radius: 22px;
}

button.ant-btn.btn-results {
  padding-right: 12px;
  padding-left: 12px;
}

button[type='button'].success,
button[type='button'].danger,
button[type='button'].confirm,
button[type='button'].cancel {
  border-radius: 22px;
}

.ant-modal-root {
  button.success,
  button.danger,
  button.confirm,
  button.deactive,
  button.cancel {
    border-radius: 22px;
  }
}

.pages__container--div-btn-export {
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 22px;
}

.ant-form-item.analytics__filter-bar-item {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin-bottom: 6px;
    }
  }
}
.ant-form-item-control-input .ant-form-item-control-input-content .ant-input-affix-wrapper.ant-input-textarea-affix-wrapper textarea {
  padding-left: 3px;
}

.ant-form-item-control-input .ant-form-item-control-input-content {
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-picker,
  input[type='text'] {
    border-radius: 22px;
    padding-left: 12px;
  }
  .ant-input-affix-wrapper input[type='text'] {
    padding-left: 3px;
  }
  .ant-input-affix-wrapper.ant-input-textarea-affix-wrapper {
    border-radius: 22px;
    textarea {
      border-radius: 22px;
    }
  }
  textarea {
    border-radius: 22px;
  }
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item .ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  &:after {
    width: 6px;
    height: 9px;
  }
}
.pages__container .ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner {
  border-radius: 50%;
  width: 16px;
  height: 16px;

  &:after {
    width: 6px;
    height: 9px;
  }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 16px;
  height: 16px;
}
